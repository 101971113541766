import React, { useContext, useEffect, useState } from "react";
import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";
import Appointmentcard from "./Appointmentcard";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import CancelIcon from "@mui/icons-material/Cancel";
import "./Calendar.css";

function Calendar() {
  const {
    month,
    day,
    currentYear,
    setCurrentYear,
    currentDate,
    setCurrentDate,
    currentMonth,
    setCurrentMonth,
    currentDay,
    setCurrentDay,
  } = useContext(GeneralUtilsContext);

  const [dates, setDates] = useState("");
  const [datePos, setDatePos] = useState("");
  const [numberOfDays, setNumberOfDays] = useState("");

  const [appt, setAppt] = useState(false);

  useEffect(() => {
    getNumberOfDays(currentMonth);

    let days = getDates(
      new Date(currentYear, currentMonth, 1),
      new Date(currentYear, currentMonth, numberOfDays)
    );

    setDates(days);

    if (!days[0]) {
      return;
    }

    setDatePos(days[0].getDay() + 1);
  }, [numberOfDays, currentMonth, currentYear, datePos]);

  function getNumberOfDays(month) {
    switch (month) {
      case 1:
        setNumberOfDays(28);
        break;

      case 0:
        setNumberOfDays(31);
        break;

      case 2:
        setNumberOfDays(31);
        break;

      case 4:
        setNumberOfDays(31);
        break;

      case 6:
        setNumberOfDays(31);
        break;

      case 9:
        setNumberOfDays(31);
        break;

      case 11:
        setNumberOfDays(31);
        break;

      default:
        setNumberOfDays(30);
        break;
    }
  }

  // Returns an array of dates between the two dates
  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = startDate;
    const addDays = function (days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  return (
    <div className="calendar_wrapper">
      <div className="calendar_header">
        <div className="cal_nav">
          <ArrowLeftIcon
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();

              if (currentMonth < 1) {
                setCurrentMonth(11);
                setCurrentYear(currentYear - 1);
              } else {
                setCurrentMonth(currentMonth - 1);
              }
            }}
          />
          <h1>{`${month[currentMonth]}`}</h1>
          <h2>{`${currentYear}`}</h2>
          <ArrowRightIcon
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();

              if (currentMonth > 10) {
                setCurrentMonth(0);
                setCurrentYear(currentYear + 1);
              } else {
                setCurrentMonth(currentMonth + 1);
              }
            }}
          />
        </div>
        <div className="col">
          <h3>{day[0]}</h3>
          <h3>{day[1]}</h3>
          <h3>{day[2]}</h3>
          <h3>{day[3]}</h3>
          <h3>{day[4]}</h3>
          <h3>{day[5]}</h3>
          <h3>{day[6]}</h3>
        </div>
      </div>
      <div className="calendar">
        {dates &&
          dates.map((date, index) =>
            index === 0 ? (
              <div
                key={index}
                className="calendarcard"
                style={{ gridColumnStart: datePos }}
                onClick={() => {
                  !appt ? setAppt(true) : setAppt(false);
                  setCurrentDay(date.getDay());
                  setCurrentDate(date.getDate());
                  setCurrentYear(date.getFullYear());
                }}
              >
                <h1 key={date.id}>{date.getDate()}</h1>
              </div>
            ) : (
              <div
                key={index}
                className="calendarcard"
                onClick={() => {
                  !appt ? setAppt(true) : setAppt(false);
                  setCurrentDay(date.getDay());
                  setCurrentDate(date.getDate());
                  setCurrentYear(date.getFullYear());
                }}
              >
                <h1 key={date.id}>{date.getDate()}</h1>
              </div>
            )
          )}
      </div>

      {appt ? (
        <div className="component_wrapper_fixed">
          <Appointmentcard
            day={day[currentDay]}
            monthInt={currentMonth}
            date={currentDate}
            year={currentYear}
          />
          <div className="circle_wrapper">
            <CancelIcon onClick={() => setAppt(false)}/>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Calendar;
