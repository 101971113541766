import React, { useState } from "react";
import Avatar from "../Avatar/Avatar";
import CancelIcon from '@mui/icons-material/Cancel';

function Rostercard({ id, photo, name, email, created, log, status, points}) {

  const [toggleProfile, setToggleProfile] = useState(false)

  return (

    <>
      <div className="roster_profile_view" style={{right: toggleProfile ? "0" : "-100vw"}}>

        <div className="avatar" style={{ backgroundImage: `url(${photo})` }}>
        </div>

        <div className="info">
          <span>
            Name: <h2>{name}</h2>
          </span>

          <span>
            Email: <h2>{email}</h2>
          </span>

          <span>
            Status: <h2>{status}</h2>
          </span>

          <span>
            Points: <h2>{points}</h2>
          </span>

          <span>
            <h5>Date Created: </h5>
            <h6>{created}</h6>
          </span>

          <span>
            <h5>Last Log In: </h5>
            <h6>{log}</h6>
          </span>
        </div>

        <button onClick={() => setToggleProfile(false)}><CancelIcon /></button>

      </div>

      <div className="rostercard" onClick={() => toggleProfile ? setToggleProfile(false) : setToggleProfile(true)}>
        <div className="col">
          <img src={photo} className="avacon" alt="avatar" />
          <div className="row">
            <h1>{name}</h1>
            <h2>{email}</h2>
            <h3>{`Joined: ${created}`}</h3>
            <h4>{`Last login: ${log}`}</h4>
          </div>       
        </div>
      </div>
    </>
  );
}

export default Rostercard;
