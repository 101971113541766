import React from 'react'
import "./AddDoc.css"
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../utils/Firebase-Config';

function AddDoc({title, header, text, type, pointsReq}) {

    const createDoc = async () => {

        if (!title) {
            window.alert("Missing fields")
            return
        }

        try {

            await addDoc(collection(db, "courses"), {
      
                title: title,
                header: header,
                text: text,
                timestamp: serverTimestamp(),
                isPublished: true,
                type: type,
                pointsRequired: pointsReq,
    
            });

        } catch (error) {
            console.log(error);
        }     
 
     }


  return (
    <button className='add_doc_btn' onClick={() => createDoc()}>Create</button>
  )

}

export default AddDoc