import React, { useState, useEffect } from "react";
import "./Splash.css";
import SignUp from "../../components/SignUp/SignUp";
import Login from "../../components/Login/Login";
import Logo from "../../components/Logo/Logo";
// import Cursor from "../../components/Cursor/Cursor";

function Splash({ navbar }) {

  const [toggle, setToggle] = useState(false);

  return (
    <>
    
    {toggle ? <Login toggle={toggle}/> : <Login toggle={toggle}/>}

      <div className="splash">

        <Logo />
        
        {/* {toggle ? <SignUp toggle={toggle}/> : <SignUp toggle={toggle}/>} */}
        
      </div>
    </>
  );
}

export default Splash;
