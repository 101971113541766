import React, { useContext } from "react";
import { auth, db, storage } from "../../utils/Firebase-Config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { updateProfile } from "firebase/auth";
import "./Avatar.css";
import { UserProfileContext } from "../../utils/UserProfileContext";
import { collection, doc, setDoc } from "firebase/firestore";

function Avatar({isUpdateable}) {

  const {photo, setPhoto} = useContext(UserProfileContext)

  const handleImageUpload = (e) => {
    e.preventDefault();

    const image = e.target.files[0];

    const imageRef = ref(
      storage,
      `/users/${auth.currentUser.uid}/avatars/${image.name}`
    );

    uploadBytes(imageRef, image)
      .then(() => {
        getDownloadURL(imageRef)
          .then((url) => {
            setPhoto(url);
            updateProfile(auth.currentUser, {
              photoURL: url,
            })
              .then(() => {
                const { uid } = auth.currentUser;        
        const settingsRef = collection(db, "users");

        setDoc(
          doc(settingsRef, uid),
          {
            photo: auth.currentUser.photoURL
          },
          { merge: true }
        )
              })
              .catch((error) => {
                // An error occurred
                // ...
              });
          })
          .catch((error) => {
            console.log(error.message, "Error getting the image url.");
          });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    
    <div className="avatar" style={{ backgroundImage: `url(${photo})` }}>
      {isUpdateable ? <input type="file" onChange={handleImageUpload} /> : ""}
    </div>
  );
}

export default Avatar;
