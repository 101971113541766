import { Navigate, Outlet } from "react-router-dom";
import { UserAuth } from './AuthContext';

const PrivateRoutes = ({ children }) => {

  const { user } = UserAuth();

    return (

        user ? <Outlet /> : <Navigate to="/"/>
        
    )

}

export default PrivateRoutes 