import React, { useState, useEffect } from "react";
import "./PageSlider.css";
import Slide from "./Slide";
import { db } from "../../utils/Firebase-Config";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";

function PageSlider() {
  const [courses, setCourses] = useState(null);
  const [count, setCount] = useState(1);
  const [slide, setSlide] = useState(0);

  const isPurchased = true;

  const slideStop = (count*-100)+100;

  useEffect(() => {
    
    const q = query(
      collection(db, "courses"),
      orderBy("timestamp", "desc"),
      where("type", "==", "free")
    );

      onSnapshot(q, (querySnapshot) => {

        const temp = [];

        querySnapshot.forEach((doc) => {
          temp.push({ id: doc.id, data: doc.data() });
        });

        setCourses(temp);
        setCount(temp.length);

      });

  }, []);

  const slideEngine = (dir) => {

    if (slide/count < 1) {
      setSlide(0)
    }

    if (dir === "left") {
      setSlide(slide + 100);

      return;
    }

    setSlide(slide - 100);

  };

  return (
    <div className="pageSlider_wrapper">
      <div
        className="pageSlider"
        style={{ gridTemplateColumns: `repeat(${count}, 100vw`, left: `${slide}vw` }} >
        {courses &&
          courses.map((course, index) => (
            <Slide
              key={index}
              id={course.id}
              index={index}
              timestamp={course.timestamp}
              title={course.data.title}
              header={course.data.header}
              text={course.data.text}
              status={course.data.status}
              type={course.data.type}
              reciept={true}
            />
          ))} 

      </div>

      <button className="scroll_btn left" style={{display: slide < 0 ? "grid" : "none"}} onClick={() => {slideEngine("left")}}></button>

      <button className="scroll_btn right" style={{display: slide <= slideStop ? "none" : "grid"}} onClick={() => {slideEngine("right")}}></button>

    </div>
  );
}

export default PageSlider;
