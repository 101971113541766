import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../utils/AuthContext";
import "./Login.css";
import Logo from "../Logo/Logo";

function Login({pageRoute, toggle}) {
  const { login, setPending } = UserAuth();

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      return alert("Fill out all fields.");
    }

    try {
      setError("");
      setPending(true);
      await login(email, password).then(() => {
        navigate("/home");
      });
    } catch (e) {
      setError(e.message);
      console.log(e.message, error);
    }
    setPending(false);
  };

  return (

      <div className="log_container">

        <form className="login" onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            id="email "
            type="email"
            placeholder="tyrone.biggums@crack.org"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div className="col">
            <button
              type="reset"
              onClick={() => {
                "";
              }}
            >
              Cancel
            </button>
            <button type="submit">Login</button>
          </div>
        </form>

        <div className="site_link_format" onClick={() => navigate("/create")}>Sign up for free account</div>

      </div>

  );
}

export default Login;
