import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../../utils/Firebase-Config";
import "./Scheduler.css";
import Schedulercard from "./Schedulercard";

function Scheduler() {
  const [schedule, setSchedule] = useState([]);
  const [queryAction, setQueryAction] = useState("appointment");

  useEffect(() => {
    if (!auth.currentUser) {
      return;
    }

    try {
      const q = query(
        collection(db, "appointments"),
        orderBy(queryAction, "asc") //where("status", "==", "pending"),
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let appointmentsList = [];
        querySnapshot.forEach((doc) => {
          appointmentsList.push({ ...doc.data(), id: doc.id });
        });
        setSchedule(appointmentsList);
      });

      return () => unsubscribe();
    } catch (error) {
      console.log(error);
    }
  }, [queryAction]);

  return (
    <div className="scheduler_wrapper">
      <div className="scheduler">
        <h1>Appointments & Events</h1>

        <div className="schedulebar">
          <select
            onChange={(e) => {
              e.preventDefault();
              setQueryAction(e.target.value);
            }}
          >
            <option value="appointment" defaultValue>
              Order
            </option>
            <option value="appointment" defaultValue>
              Appointment
            </option>
            <option value="status">Status</option>
            <option value="name">Name</option>
          </select>
        </div>

        {schedule &&
          schedule.map((appt, index) => (
            <Schedulercard
              key={index}
              id={appt.id}
              photo={appt.photo}
              name={appt.name}
              email={appt.email}
              appointment={appt.appointment}
              status={appt.status}
            />
          ))}
      </div>
    </div>
  );
}

export default Scheduler;
