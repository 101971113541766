import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./utils/PrivateRoutes";
import { AuthContextProvider } from "./utils/AuthContext";
import UserProfileContextProvider from "./utils/UserProfileContext";
import Classroom from "./pages/Classroom/Classroom";
import Home from "./pages/Home/Home";
import Splash from "./pages/Splash/Splash";
import Admin from "./pages/Admin/Admin";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Navbar from "./components/Navbar/Navbar";
import Oops from "./pages/Oops/Oops";
//import Connect from "./pages/Connect/Connect";
//import FinanceSystem from "./components/FinanceSystem/FinanceSystem";
import Stocks from "./pages/Stocks/Stocks";
import Login from "./components/Login/Login";
import SignUp from "./components/SignUp/SignUp";
import PaymentPlans from "./components/PaymentSystem/PaymentPlans";
import Profile from "./pages/Profile/Profile";
import Create from "./pages/Create/Create";
//import CourseCard from "./components/CourseCard/CourseCard";

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <UserProfileContextProvider>
          <Routes>
            <Route element={<PrivateRoutes />}>
              {/* RESTRICTED ACCESS ROUTES */}
              <Route
                path="/home"
                element={<Home key={Math.random()} navbar={<Navbar />} />}
              />
              {/* <Route
                path="/classroom"
                element={<Classroom key={Math.random()} navbar={<Navbar />} />}
              /> */}
              <Route
                path="/about"
                element={<About key={Math.random()} navbar={<Navbar />} />}
              />
              <Route
                path="/contact"
                element={<Contact key={Math.random()} navbar={<Navbar />} />}
              />
              {/* <Route
                path="/stocks"
                element={<Stocks key={Math.random()} navbar={<Navbar />} />}
              /> */}
              <Route
                path="/admin"
                element={<Admin key={Math.random()} navbar={<Navbar />} />}
              />

              <Route
                path="/plans"
                element={<PaymentPlans key={Math.random()} navbar={<Navbar />} />}
              />

              <Route
                path="/profile"
                element={<Profile key={Math.random()} navbar={<Navbar />} />}
              />


            </Route>

            {/* PUBLIC ACCESS ROUTES */}

            <Route
                path="/create"
                element={<Create key={Math.random()} navbar={<Navbar />} />}
              />

            <Route
              path="/*"
              element={<Oops key={Math.random()} navbar={null} />}
            />
            <Route
              exact
              path="/"
              element={<Splash key={Math.random()} navbar={null} />}
            />
          </Routes>
        </UserProfileContextProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
