import React, { useContext } from "react";
import "./DeleteDoc.css";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../utils/Firebase-Config";
import { UserProfileContext } from "../../utils/UserProfileContext";
import DeleteIcon from '@mui/icons-material/Delete';

function DeleteDoc({ path, id }) {

  const {status} = useContext(UserProfileContext)

  const x = async (e) => {
    e.preventDefault();

    if (window.confirm(`Sure you want to delete this document? ${id}`)) {
      await deleteDoc(doc(db, path, id)).then(() => window.location.reload());
      
    } else {
      return;
    }
  };

  return <button style={{display: status === "admin" ? "grid" : "none"}} onClick={(e) => x(e)}><DeleteIcon /></button>;
}

export default DeleteDoc;
