import React from 'react'
import "./Oops.css"

function Oops({navbar}) {

  return (
    <>
      {navbar}
      <div className="oops_layout">

        <h1>404</h1>
        <p>Something went arye.</p>

      </div>
    </>
  )
}

export default Oops