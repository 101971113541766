import React, { useState } from "react";
import {Navigate, useNavigate} from "react-router-dom"
import "./SignUp.css";
import { UserAuth } from "../../utils/AuthContext";
import Logo from "../Logo/Logo";

function SignUp({pageRoute}) {

  const { createUser, pending, setPending } = UserAuth();

  const navigate = useNavigate()

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (confirm !== password) {    
      return alert("Passwords do not match");
    }

    try {
      setError("");
      setPending(true);
      await createUser(email, password);
      navigate(pageRoute)
    } catch (e) {
      setError(e.message);
      console.log(e.message, error);
    }
    
    setPending(false);

  };

  return (

      <div className="wrapper">

        <form className="signUp" onSubmit={handleSubmit}>
          <div className="row"><label htmlFor="email">Email</label>
          <input
            id="email "
            type="email"
            placeholder="tyrone.biggums@crack.org"
            onChange={(e) => setEmail(e.target.value)}
            required
          /></div>
          <div className="row"><label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            placeholder="Capitals, Numbers, Punctuations"
            onChange={(e) => setPassword(e.target.value)}
            required
          /></div>
          <div className="row"><label htmlFor="confirm">Password Confirm</label>
          <input
            id="confirm"
            type="password"
            placeholder="Capitals, Numbers, Punctuations"
            onChange={(e) => setConfirm(e.target.value)}
            required
          /></div>
          <div className="col">
            <button
              type="reset"
              onClick={() => {
                "";
              }}
            >
              Cancel
            </button>
            <button disabled={pending} type="submit">
              Create
            </button>
          </div>
        </form>
        
      </div>

  );
}

export default SignUp;
