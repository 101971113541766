import React, { useContext, useState } from 'react'
import UpdateIcon from '@mui/icons-material/Update';
import CancelIcon from '@mui/icons-material/Cancel';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../utils/Firebase-Config';
import { UserProfileContext } from '../../utils/UserProfileContext';
import "./Update.css"

function Update({ path, id}) {

    const {status} = useContext(UserProfileContext)

    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [toggleEdit, setToggleEdit] = useState(false)

    const update = async (e) => {
        e.preventDefault();

            const docRef = doc(db, path, id);

            if (!title && !text) {return}

            if (!text) {
                await updateDoc(docRef, {
        
                //KEYS & VALUES THAT NEED UPDATING
                caption: title,
            
                },{ merge: true });

                setToggleEdit(false)

                return
            }

            if (!title) {
                await updateDoc(docRef, {
        
                //KEYS & VALUES THAT NEED UPDATING
                text: text
            
                },{ merge: true });

                setToggleEdit(false)

                return
            }          
    
            await updateDoc(docRef, {
        
            //KEYS & VALUES THAT NEED UPDATING
            caption: title,
            text: text
        
            },{ merge: true });

            setToggleEdit(false)

      };

            return (

    <>

        <div className="edit_box" style={{left: toggleEdit ? "0" : "-100vw"}}>

            <div className="row">

                <form onSubmit={(e) => update(e)}>
                    <input type="text" placeholder="New Sub-title" onChange={(e) => setTitle(e.target.value)}/>
                
                    <textarea type="text" placeholder="New Description" onChange={(e) => setText(e.target.value)}/> 

                    <div className="col">
                        <button type='reset'>Cancel</button>
                        <button type='submit'>Update</button>
                    </div>
                </form>
                        
            </div>

            <button onClick={() => setToggleEdit(false)}><CancelIcon /></button>

        </div>

        <div>
            <button style={{display: status === "admin" ? "grid" : "none"}} onClick={() => setToggleEdit(true)}><UpdateIcon /></button>
        </div>
    
    </>

  )
}

export default Update