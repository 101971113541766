import React, { useContext } from 'react'
import { UserProfileContext } from '../../utils/UserProfileContext'
import "./Subscription.css"

function Subscription() {

    const { subscription } = useContext(UserProfileContext)

  return (

    <div className="subscription_statusbar">
        Subscription: {subscription ? subscription.role : "Pending..."}
        {subscription && <h1>Renewal date: {new Date(subscription?.current_period_end.seconds * 1000).toLocaleDateString()}</h1>}
    </div>

  )
}

export default Subscription