import React, { useState } from "react";
import "./CreateCourseForm.css";
import AddDoc from "../AddDoc/AddDoc";
import CloseIcon from '@mui/icons-material/Close';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

function CreateCourseForm() {
  const [title, setTitle] = useState(null);
  const [header, setHeader] = useState(null);
  const [text, setText] = useState(null);
  const [type, setType] = useState("free");
  const [pointsReq, setPointsReq] = useState(0);

  const [toggleCreateModule, setToggleCreateModule] = useState(false)


  return (

    <>
    <button className="add_doc_btn" onClick={() => {
      if (toggleCreateModule) {
        setToggleCreateModule(false)
      }else{
        setToggleCreateModule(true)
      }
    }}><CreateNewFolderIcon /></button>

    <div className="component_wrapper" style={{right: toggleCreateModule ? "0" : `-100vw` }}>

      <h1>Create New Module</h1>

      <div className={`create_course_form`}>

        <div className="row">
          <label htmlFor="field1">title</label>
          <input
            required
            name="field1"
            type="text"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="row">
          <label htmlFor="field2">Sub Header</label>
          <input
            required
            name="field2"
            type="text"
            onChange={(e) => setHeader(e.target.value)}
          />
        </div>

        <div className="row">
          <label htmlFor="field3">Module Description</label>
          <textarea
            required
            name="field3"
            type="text"
            onChange={(e) => setText(e.target.value)}
          />
        </div>

        <div className="row">
          <label htmlFor="field4">Points Required</label>
          <input
            required
            name="field4"
            type="number"
            max="1000000"
            maxLength="7"
            defaultValue={0}
            onChange={(e) => setPointsReq(parseInt(e.target.value))}
          />
        </div>

        <div className="row">
        <label htmlFor="field5">Content Type</label>
          <select name="field5" onChange={(e) => setType(e.target.value)}>
            <option defaultValue="free" value="free">free</option>
            <option value="subscription">subscription</option>
          </select>
        </div>

        <div className="col">
          <button className="close_btn" onClick={() => {
            if (toggleCreateModule) {
              setToggleCreateModule(false)
            }else{
              setToggleCreateModule(true)
            }
          }}><CloseIcon /></button>

          <AddDoc title={title} header={header} text={text} type={type} pointsReq={pointsReq}/>
        </div>

      </div>
    </div>
    </>
  );
}

export default CreateCourseForm;
