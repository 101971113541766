import React from 'react'
import "./Contact.css"

function Contact({navbar}) {

  return (
    <>
      {navbar}
      <div className="home_layout">
        <div className="left_side_panel"></div>

        <div className="content_panel"><h1>Contact</h1></div>

        <div className="right_side_panel"></div>
      </div>
    </>
  )
}

export default Contact