import React, { useContext, useEffect, useState } from "react";
import {
  collection,
  doc,
  setDoc
} from "firebase/firestore";
import { db } from "../../utils/Firebase-Config";
import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

function Schedulercard({ id, photo, name, email, appointment, status }) {
  const { day, month } = useContext(GeneralUtilsContext);

  const [date, setDate] = useState("");
  const [toggle, setToggle] = useState(false);
  const [meridiem, setMeridiem] = useState("am");

  const [statusAction, setStatusAction] = useState("");
  const [statusColor, setStatusColor] = useState("");

  useEffect(() => {
    setStatusAction(status);

    switch (status) {
      case "Pending":

      setStatusColor("yellow")
        
        break;
        case "Confirmed":

        setStatusColor("green")
          
          break;
          case "Cancel":

          setStatusColor("red")
            
            break;
          
      default:
        break;
    }

    const time = new Date(appointment);
    const dy = day[time.getDay()];
    const mnth = month[time.getMonth()];
    const date = time.getDate();
    const year = time.getFullYear();
    const hours = time.getHours();
    var minutes = time.getMinutes();

    if (hours > 12) {
      setMeridiem("pm");
    }

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    setDate(`${dy} ${mnth} ${date}, ${year} | ${hours}:${minutes} ${meridiem}`);
  }, [day, month, appointment, status, statusAction, meridiem]);

  const updateStatus = async (e) => {

        //Store to DB
        const settingsRef = collection(db, "appointments");

        await setDoc(doc(settingsRef, id), {

          status: e
        },
        { merge: true });
  }

  return (
    <div
      className="schedulercard"
    >
      <img src={photo} className="avacon"  style={{ outline: `solid 5px ${statusColor}`,  outlineOffset: "-5px"  }} alt="avatar" />
      <div className="row">
        <h1>{name}</h1>
        <h2>{email}</h2>
        <h3>{date.toString()}</h3>
      </div>
      <div className="row">
        <h4>Status:</h4>
        <MoreHorizIcon
          onClick={() => {
            console.log("clicked");
            !toggle ? setToggle(true) : setToggle(false);
          }}
        />

        {toggle ? (
          <div className="status_actions">
            <div className="col">
              <h1>Pending</h1>
              <input
                type="radio"
                name="status"
                value="Pending"
                onClick={(e) => {
                  // eslint-disable-next-line no-restricted-globals
                  if (confirm("Confirm change of appoinment status.")) {
                  setStatusAction(e.target.value);
                  setStatusColor("yellow");
                  updateStatus(e.target.value)
                  setToggle(false)                    
                  }
                  setToggle(false)
                }}
              />
            </div>

            <div className="col">
              <h1>Confirmed</h1>
              <input
                type="radio"
                name="status"
                value="Confirmed"
                onClick={(e) => {
                  // eslint-disable-next-line no-restricted-globals
                  if (confirm("Confirm change of appoinment status.")) {
                    setStatusAction(e.target.value);
                    setStatusColor("green");
                    updateStatus(e.target.value)
                    setToggle(false)                    
                    }
                    setToggle(false)
                }}
              />
            </div>

            <div className="col">
              <h1>Cancel</h1>
              <input
                type="radio"
                name="status"
                value="Cancel"
                onClick={(e) => {
                  // eslint-disable-next-line no-restricted-globals
                  if (confirm("Confirm change of appoinment status.")) {
                    setStatusAction(e.target.value);
                    setStatusColor("red");
                    updateStatus(e.target.value)
                    setToggle(false)                    
                    }
                    setToggle(false)
                }}
              />
            </div>

            <div className="col">
              <h1>Blacklisted</h1>
              <input
                type="radio"
                name="status"
                value="Blacklisted"
                onClick={(e) => {
                  // eslint-disable-next-line no-restricted-globals
                  if (confirm("Confirm change of appoinment status.")) {
                    setStatusAction(e.target.value);
                    setStatusColor("black");
                    updateStatus(e.target.value)
                    setToggle(false)                    
                    }
                    setToggle(false)
                }}
              />
            </div>

            {/* <h4>{statusAction}</h4> */}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Schedulercard;
