import React, { useContext, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DatePicker from "./DatePicker";
import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { auth, db } from "../../utils/Firebase-Config";

function Appointmentcard({ day, monthInt, date, year }) {
  const { month } = useContext(GeneralUtilsContext);

  const { uid } = auth.currentUser;

  const [miliseconds, setMiliseconds] = useState(32400000);

  const [toggle, setToggle] = useState(false);

  const setAppointment = async (e, time) => {
    console.log(time);

    try {
      const docRef = doc(db, "appointments", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        alert(
          "This action will cancel your previous appoinment. Press cancel if you do not wish this to happen."
        );
        //Store to DB
        const settingsRef = collection(db, "appointments");

        await setDoc(doc(settingsRef, uid), {
          name: auth.currentUser.displayName,
          email: auth.currentUser.email,
          appointment: time,
          status: "Pending",
          timestamp: serverTimestamp()
        });
      } else {
        //Store to DB
        const settingsRef = collection(db, "appointments");

        await setDoc(doc(settingsRef, uid), {
          name: auth.currentUser.displayName,
          email: auth.currentUser.email,
          appointment: time,
          status: "Pending",
          timestamp: serverTimestamp()
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {toggle ? (
        <DatePicker
          onClick={() => {
            toggle ? setToggle(false) : setToggle(true);
          }}
        />
      ) : null}
      <div className="appointmentcard">
        <div className="col">
          <CalendarMonthIcon />
          <p>Set appointment for: </p>
        </div>
        <div></div>
        <h3
          onClick={() => {
            toggle ? setToggle(false) : setToggle(true);
          }}
        >
          {day} {month[monthInt]} {date}, {year}
        </h3>
        <form
          onSubmit={(e) => {
            e.preventDefault();

            const time = new Date(year, monthInt, date, 0, 0, 0, miliseconds);

            setAppointment(e, time.getTime());

            console.log(`Appointment set for: ${time.toString()} ()`);

          }}
        >
          <input
            type="time"
            defaultValue={"09:00"}
            onChange={(e) => setMiliseconds(e.target.valueAsNumber)}
          />
            <button type="submit">Set</button>         
        </form>
      </div>
    </>
  );
}

export default Appointmentcard;
