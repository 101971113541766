import React, { useContext } from "react";
import BlogSystem from "../../components/BlogSystem/BlogSystem";
import "./Home.css";
import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";
import { UserProfileContext } from "../../utils/UserProfileContext";
import PageSlider from "../../components/PageSlider/PageSlider";
import Blog from "../../components/Blogger/Blog";

function Home({ navbar }) {

  const {status} = useContext(UserProfileContext)
  const { toggleBlogForm, setToggleBlogForm, setEdit } =
    useContext(GeneralUtilsContext);

  return (
    
    <>   

      {navbar}

      <div className="home_layout">

        <Blog />

      </div>
      
    </>
    
  );
}

export default Home;