import React from "react";
import "./About.css";
import profile from "../../images/jpg/profile_pic_about.jpg";

function About({ navbar }) {
  return (
    <>

      {navbar}

      <div className="about_layout">

        <img src={profile} />

        <span>
        </span>

      </div>

    </>
  );
}

export default About;
