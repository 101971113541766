import React from "react";
import FinanceSystem from "../../components/FinanceSystem/FinanceSystem";
import Account from "../../components/Account/Account"
function Stocks({ navbar }) {
  return (
    <div>
      {navbar}
      <FinanceSystem />
      <Account />
    </div>
  );
}

export default Stocks;
