import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth, db } from "./Firebase-Config";
import { collection, doc, setDoc } from "firebase/firestore";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [pending, setPending] = useState(true);
  const [error, setError] = useState(true);

  const createUser = (email, password) => {
    try {
      return createUserWithEmailAndPassword(auth, email, password).then (() => {

        const { uid } = auth.currentUser;        
        const settingsRef = collection(db, "users");

        setDoc(
          doc(settingsRef, uid),
          {
            photo: auth.currentUser.photoURL,
            name: auth.currentUser.displayName,
            email: auth.currentUser.email,
            created: auth.currentUser.metadata.creationTime,
            status: "scholar",
            points: 100, 
            log: auth.currentUser.metadata.lastSignInTime
          },
          { merge: true }
        )

        });
    } catch (e) {
      setError(e.message);
      console.log(e.message, error);
    }
  };

  const login = (email, password) => {
    try {
      return signInWithEmailAndPassword(auth, email, password)
    } catch (e) {
      setError(e.message);
      console.log(e.message, error);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);

      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        setPending(false);
      });

      navigate("/");
        
      return () => {
        unsubscribe();
      };


    } catch (e) {
      setError(e.message);
      console.log(e.message, error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setPending(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (pending) {
    return <>Loading...</>;
  }

  return (
    <UserContext.Provider
      value={{ createUser, user, logout, login, setPending }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
