import React, { useState, useContext, useEffect } from "react";
import { db, storage } from "../../utils/Firebase-Config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";
import VideocamIcon from "@mui/icons-material/Videocam";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import "./Uploader.css";
import { UserProfileContext } from "../../utils/UserProfileContext";
import PostAddIcon from '@mui/icons-material/PostAdd';

function Uploader({ directory, modulePointsRequired }) {
  //ADMIN CONTROLS MUST HAVE ADMIN STATUS
  const { status } = useContext(UserProfileContext);
  //______________________________________________________________

  const { currentClassId, currentTitle, setCurrentTitle, setCurrentVideo } =
    useContext(GeneralUtilsContext);

  const { userPoints } = useContext(UserProfileContext)

  const [fileType, setFileType] = useState("image");
  const [path, setPath] = useState("");
  const [title, setTitle] = useState("");
  const [brief, setBrief] = useState("");
  const [section, setSection] = useState("lesson");
  const [pointsRequired, setPointsRequired] = useState(0);
  const [input, setInput] = useState("");
  const [icon, setIcon] = useState("");
  const [ft, setFt] = useState("image");
  const [pending, setPending] = useState(false)

  const [toggleCreateModule, setToggleCreateModule] = useState(false)

  const handlefileUpload = (e) => {
    e.preventDefault();

    if (!title) {
      return alert("Missing fields.");
    }

    const file = e.target.files[0];

    // switch (ft) {
    //   case "video":
    //     if (file.type !== "video/mp4") {
    //       return alert("Invalid file type.");
    //     }
    //     break;

    //   case "image":
    //     if (file.type !== "image/jpeg") {
    //       return alert("Invalid file type.");
    //     }
    //     break;

    //   case "pdf":
    //     if (file.type !== "application/pdf") {
    //       return alert("Invalid file type.");
    //     }
    //     break;

    //   default:
    //     break;
    // }

    const fileRef = ref(storage, `/${fileType}/${file.name}`);

    setPending(true)

    uploadBytes(fileRef, file)
      .then(() => {
        getDownloadURL(fileRef)
          .then((url) => {
            setPath(url);

            if (!title) {
              return alert("You like  teach on Sunday. No class. ~Russel");
            }

            addDoc(
              collection(db, directory),
              {
                path: url,
                type: ft,
                caption: title,
                text: brief,
                pointsRequired: pointsRequired,
                section: section,
                timestamp: serverTimestamp(),
              },
              { merge: true }
            );

          })
          .then(() => {
            setPending(false)

            setCurrentTitle("");
            setTitle("");
            setBrief("");
            setInput("");
            setCurrentVideo("");
          })
          .catch((error) => {
            console.log(error.message, "Error getting the file url.");
          });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    setFileType(ft);
    if (ft === "video") {
      setIcon(<VideocamIcon />);
    } else if (ft === "pdf") {
      setIcon(<PictureAsPdfIcon />);
    } else {
      setIcon(<PhotoSizeSelectActualIcon />);
    }
  }, [ft]);

  return (

    <>
      <div className="loading_component" style={{display: pending ? "grid" : "none"}}><h1>Loading...</h1></div>
      
      <button className="add_doc_btn" onClick={() => {
        if (toggleCreateModule) {
          setToggleCreateModule(false)
        }else{
          setToggleCreateModule(true)
        }
      }}><PostAddIcon/></button>

      <div className="component_wrapper" style={{right: toggleCreateModule ? "0" : `-100vw` }}>

        <h1>Add Media Content</h1>

        <div className="uploader">

          <div className="row">
            <label htmlFor="field1">Media Type</label>
            <select name="field1" className="" onChange={(e) => setFt(e.target.value)}>
              <option default value="image">Image</option>
              <option value="video">Video</option>
              <option value="pdf">PDF Download</option>
            </select>
          </div>

          <div className="row">
            <label htmlFor="field2">title</label>
            <input
            name="field2"
              type="text"
              value={title}
              placeholder="Title"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>

          <div className="row">
            <label htmlFor="field3">Description</label>
            <textarea
            name="field3"
              placeholder="Class Description"
              value={brief}
              onChange={(e) => {
                setBrief(e.target.value);
              }}
            ></textarea>
          </div>

          <div className="row">
            <label htmlFor="field7">Points Required</label>

              <div className="col">
                <input
                name="field7"
                  type="number"
                  // value={title}
                  defaultValue={0}
                  placeholder="Points"
                  onChange={(e) => {
                    let x = parseInt(e.target.value) + parseInt(modulePointsRequired);
                    setPointsRequired(x);
                    // console.log(modulePointsRequired, pointsRequired);
                  }}
                />
                <h1>{modulePointsRequired}/{pointsRequired}</h1>
              </div>
              
          </div>

          <div className="row">
            <label htmlFor="field4">Section</label>
            <select name="field4" className="" onChange={(e) => setSection(e.target.value)}>
              <option default value="lesson">Lesson</option>
              <option value="homework">Homework</option>
              <option value="resource">Resources</option>
            </select>
          </div>

{/* //VIDEO UPLOAD */}
          <div className="circle_wrapper">
            {icon}
            <input
              type="file"
              value={input}
              className="invisible"
              onChange={(e) => handlefileUpload(e)}
              accept="image/jpeg, image/png, video/mp4, video/mpeg, video/mov, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain"
            />
          </div>
          
        </div>
      </div>
    </>
  );
}

export default Uploader;
