import { deleteDoc, doc } from 'firebase/firestore';
import React, { useContext } from 'react'
import { db } from '../../utils/Firebase-Config';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserProfileContext } from '../../utils/UserProfileContext';
import SocialMediaTools from '../SocialMediaTools/SocialMediaTools';

function Blogcard({id, title, body, type, timestamp, url}) {

  const {status} = useContext(UserProfileContext)

  const deleteData = async () => {

    await deleteDoc(doc(db, "blogs", id));

  }

  return (
    <div className='blogcard'>
      {type ? type === "image/jpeg" ? <img src={url} alt="blog cover" /> : <video src={url} alt="footage" controls /> : null}

        <h1>{title}</h1>
        <p>{body}</p>

        <SocialMediaTools url={"http://www.bulliesandbears.com"} site={"Bullies & Bears"} title={title} desc={body} image={url} />

      {status === "admin" ? <div className="delete_button" onClick={() => deleteData()}><DeleteIcon /></div> : null}
    </div>
  )

}

export default Blogcard