import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseApp = initializeApp({
  apiKey: "AIzaSyCiK1vlWBNDZTICFHd6Ag-5Kai5ZwGWupE",
  authDomain: "a-nu-option.firebaseapp.com",
  projectId: "a-nu-option",
  storageBucket: "a-nu-option.appspot.com",
  messagingSenderId: "1003708825069",
  appId: "1:1003708825069:web:f6842c4b77d6b7d2d27be4",
  measurementId: "G-151B1RW3XM"
});

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
