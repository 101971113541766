import React from "react";
import SignUp from "../../components/SignUp/SignUp";
import "./Create.css";

function Create({ navbar }) {
  return (
    <>
      <div className="create_layout">

        <div className="create_copy_wrapper">

            <h1>WHY?</h1>

            <h2>Signing up for a free account with a Stock Options coach can offer several benefits:</h2>
            
            <div className="create_grid">
            <span>
                <h2>1. Education:</h2>
                <p>Gain access to valuable educational resources and materials on stock options trading, helping you understand the intricacies of the market and various trading strategies.</p>
            </span>

            <span>
                <h2>2. Guidance:</h2>
                <p> personalized guidance from experienced coaches who can help you navigate the complexities of options trading, answer your questions, and provide insights tailored to your individual needs and goals.</p>
            </span>

            <span>
                <h2>3. Risk Management:</h2>
                <p> Learn effective risk management techniques to mitigate potential losses and maximize profits, ensuring you make informed decisions when trading options.</p>
            </span>

            <span>
                <h2>4. Market Insights:</h2>
                <p> Stay updated with the latest market trends, news, and analysis provided by your coach, helping you make well-informed trading decisions and capitalize on opportunities as they arise.</p>
            </span>

            <span>
                <h2>5. Strategy Development:</h2>
                <p>Work with your coach to develop and refine trading strategies that align with your risk tolerance, investment objectives, and market outlook, increasing your chances of success in the options market.</p>
            </span>

            <span>
                <h2>6. Community Support:</h2>
                <p> Connect with a community of like-minded traders who are also learning and trading options, providing you with a supportive environment to share ideas, experiences, and tips.</p>
            </span>

            <span>
                <h2>7. Tools and Resources:</h2>
                <p> Access to specialized tools, software, and resources that can aid in your options trading journey, such as trading platforms, calculators, and research reports.</p>
            </span>

            <span>
                <h2>8. Performance Tracking:</h2>
                <p> Utilize tools and guidance from your coach to track your performance and analyze your trades, identifying areas for improvement and refining your trading approach over time.</p>
            </span>


            <span>
                <h2>9. Cost-effective:</h2>
                <p> Many coaches offer free accounts or trial periods, allowing you to experience their services and determine if they align with your needs and preferences before committing to a paid subscription or membership.</p>
            </span>

            <span>
                <h2>10. Confidence Building:</h2>
                <p> By learning from and interacting with experienced options traders, you can gain confidence in your trading abilities and approach the market with a greater sense of knowledge and assurance.</p>
            </span>
            </div>

        </div>

        <SignUp pageRoute={"/plans"}/>
        
      </div>
    </>
  );
}

export default Create;
