import React, { useEffect, useContext } from "react";
import { UserProfileContext } from "../../utils/UserProfileContext";
import Calendar from "../../components/Calendar/Calendar";
import Roster from "../../components/Roster/Roster";
import Scheduler from "../../components/Scheduler/Scheduler";
import "./Admin.css";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../utils/Firebase-Config";
import CreateCourseForm from "../../components/Forms/CreateCourseForm";
import Facetime from "../../components/Facetime/Facetime";

function Admin({ navbar }) {
  const navigate = useNavigate();

  const { status, setStatus } = useContext(UserProfileContext);

  
  useEffect(() => {

    const userData = async () => {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
  
      const docSnap = await getDoc(userDocRef);
  
      if (docSnap.exists()) {

        if (docSnap.data().status !== "admin") {
          navigate("/*");
        };

        setStatus(docSnap.data().status)

      } else {
        // doc.data() will be undefined in this case
        // setStatus("blocked");
      }
    };

    userData()

  }, [navigate]);

  return (
    <>
      {navbar}

      <div className="admin_layout">
            
        <div className="left_side_panel">

          <div className="wrapper">

            <h1>Go Live</h1>
       
            {/* <Facetime /> */}

          </div>               

        </div>
        
        <div className="content_panel">

          <Calendar />
          <Scheduler />

        </div>

        <div className="right_side_panel">

          <Roster />

        </div>

      </div>
    </>
  );
}

export default Admin;
