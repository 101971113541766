import React, { useState } from "react";
import "./UpdateField.css";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../utils/Firebase-Config";

function UpdateField({ path, id, defaultValue, keyField }) {

  const [newValue, setNewValue] = useState();

  const update = async () => {

    if (!newValue) {
      return;
    }

    const docRef = doc(db, `${path}/${id}`);

    // Set the "capital" field of the city 'DC'
    try {
      await updateDoc(
        docRef,
        {
          //KEYS & VALUES THAT NEED UPDATING
          [keyField]: newValue,
        },
        { merge: true }
      );
    } catch (error) {
      console.log(error);
    }

  };

  return (
    <div className="update_field_wrapper">
      <div className="update_field">
        <label htmlFor="field">{keyField}</label>
        <input
          name="field"
          type="text"
          defaultValue={defaultValue}
          onChange={(e) => setNewValue(e.target.value)}
        />
        <button className="update_btn" onClick={() => update()}>
          Update
        </button>
      </div>
    </div>
  );
}

export default UpdateField;
