import React from "react";
import { UserAuth } from "../../utils/AuthContext";
import "./Logout.css";
import LogoutIcon from '@mui/icons-material/Logout';

export default function Logout() {
  const { logout } = UserAuth();

  return (


      <div className="logout">
        <button onClick={logout}><div className="col"><LogoutIcon /><h3>Logout</h3></div></button>
      </div>

  );
}
